<script setup lang="ts">
import { ROUTES } from '~/constants/routes';

interface Props {
    isHovered: boolean; // активность ховера
}

withDefaults(defineProps<Props>(), {});

const { t } = useI18n();
const isLoading = ref(true);

onMounted(() => {
    isLoading.value = false;
});

const menuItems = [
    {
        id: 1,
        link: ROUTES.bookingAdmin,
        icon: 'ri-home-line',
        text: t('routes.bookings'),
    },
]
</script>

<template>
    <div class="menu">
        <template
            v-for="item in menuItems"
            :key="item.id"
        >
            <div v-if="isLoading" class="loader-wrapper">
                <v-skeleton-loader
                    color="neutral04"
                    class="loader"
                    type="list-item"
                />
            </div>
            <NuxtLink 
                v-if="!isLoading"
                :to="item.link" 
                activeClass="active" 
                class="menu-item"
            >
                <i :class="['menu-item__icon', item.icon, 'ri-lg']"/>
                <span :class="['menu-item__text', { '_hover': isHovered }]">{{ item.text }}</span>
            </NuxtLink>
        </template>
    </div>
</template>

<style scoped lang="scss">
.loader {
    width: 100%;
    height: 22px;

    &.v-skeleton-loader {
        background-color: transparent !important;
    }

    :deep(.v-skeleton-loader__bone) {
        margin: 0;
    }

    &-wrapper {
        width: 46px;
        padding: 10px 12px;
    }
}

.menu {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &-item {
        display: flex;
        padding: 10px 12px;
        align-items: center;

        @include responsive( 'mobile' ) {
            margin: 12px 8px 0px 8px;
            padding: 0px 0px 20px 0px;
        }

        &.active {
            @include responsive( 'mobile' ) {
                border-bottom: 1px solid $hover-link-color;
            }

            .menu-item__icon {
                color: $main-active-color;
            }
            .menu-item__text {
                color: $main-active-color;
            }
        }

        &__icon {
            width: 20px;
            height: 20px;
            color: $main-text-color;
        }

        &__text {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.16px;
            color: $main-text-color;
            transition: all 0.2s ease-out;
            max-width: 0;
            opacity: 0;
            width: auto;

            &._hover {
                padding-left: 8px;
                max-width: auto;
                opacity: 1;
            }
        }
    }
}
</style>