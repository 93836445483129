<script setup lang="ts">
import Logout from '~/components/shared/Common/Logout.vue';

const isLoading = ref<boolean>(true);

onMounted(() => {
    isLoading.value = false;
});

</script>

<template>
    <header class="header">
        <div class="header__container">
            <div class="logo">
                <v-skeleton-loader 
                    v-if="isLoading"
                    color="neutral04" 
                    type="image" 
                    class="logo-loader"
                />
                <img v-else src="/images/logo.svg">
            </div>
            <div class="logout">
                <v-skeleton-loader 
                    v-if="isLoading"
                    color="neutral04" 
                    class="logout-loader"
                    type="chip" 
                />
                <div v-else class="logout">
                    <Logout />
                </div>
            </div>
        </div>
    </header>
</template>

<style scoped lang="scss">
.header {
    width: 100%;

    &__container {
        padding: 16px 28px;
        width: 100%;
        max-width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 1500px;

        @include responsive('mobile') {
            padding: 20px 24px;
        }
    }

    .logo {
        max-width: 125px;

        &-loader {
            width: 125px;

            :deep(.v-skeleton-loader__bone) {
                margin: 0;
                width: 100%;
                height: 20px;
            }
        }
    }
}

.logout {
    &-loader {
        width: 44px;
        display: flex;
        height: 44px;
        background-color: transparent !important;

        :deep(.v-skeleton-loader__bone) {
            margin: 0;

            &.v-skeleton-loader__text {
                @include responsive("mobile-small") {
                    margin-left: 12px;
                    max-width: 40px;
                }
            }

        }
    }
}
</style>

