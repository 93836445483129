<script setup lang="ts">
import Menu from '~/components/shared/Common/wide/Menu.vue';

const isHovered = ref<boolean>(false);

const hoverOn = () => {
    isHovered.value = true;
};

const hoverOff = () => {
    isHovered.value = false;
};
</script>

<template>
    <div 
        class="navigation"
        :class="{ '_hover': isHovered }"
        @mouseover="hoverOn"
        @mouseleave="hoverOff"
    >
        <div class="navigation-container">
            <Menu :isHovered="isHovered" />
        </div>
    </div>
</template>

<style scoped lang="scss">
.navigation {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    height: 100%;
    padding: 28px 16px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    align-self: stretch;
    background-color: $color-neutral01;
    max-width: 76px;
    width: 100%;
    transition: all 0.3s ease;

    &._hover {
        box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
        max-width: 280px;
    }

    .navigation-container {
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;
        align-items: flex-start;
        align-self: stretch;
        gap: 28px;
    }
}
</style>